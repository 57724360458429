exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-containers-blog-categories-index-js": () => import("./../../../src/containers/BlogCategories/index.js" /* webpackChunkName: "component---src-containers-blog-categories-index-js" */),
  "component---src-containers-blog-index-js": () => import("./../../../src/containers/Blog/index.js" /* webpackChunkName: "component---src-containers-blog-index-js" */),
  "component---src-containers-blog-post-index-js": () => import("./../../../src/containers/BlogPost/index.js" /* webpackChunkName: "component---src-containers-blog-post-index-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-de-cookies-js": () => import("./../../../src/pages/aviso-de-cookies.js" /* webpackChunkName: "component---src-pages-aviso-de-cookies-js" */),
  "component---src-pages-aviso-de-privacidade-js": () => import("./../../../src/pages/aviso-de-privacidade.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidade-js" */),
  "component---src-pages-boletos-js": () => import("./../../../src/pages/boletos.js" /* webpackChunkName: "component---src-pages-boletos-js" */),
  "component---src-pages-cnpj-js": () => import("./../../../src/pages/cnpj.js" /* webpackChunkName: "component---src-pages-cnpj-js" */),
  "component---src-pages-form-1-index-js": () => import("./../../../src/pages/form1/index.js" /* webpackChunkName: "component---src-pages-form-1-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integre-com-nossos-produtos-js": () => import("./../../../src/pages/integre-com-nossos-produtos.js" /* webpackChunkName: "component---src-pages-integre-com-nossos-produtos-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-portal-do-titular-dos-dados-7194356877-js": () => import("./../../../src/pages/portal-do-titular-dos-dados-7194356877.js" /* webpackChunkName: "component---src-pages-portal-do-titular-dos-dados-7194356877-js" */),
  "component---src-pages-regulamento-pix-zero-js": () => import("./../../../src/pages/regulamento-pix-zero.js" /* webpackChunkName: "component---src-pages-regulamento-pix-zero-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

